<template>
  <div class="cardEngagementLevel"
      :class="engagementLevelClass"
  >
    <div class="list">
      <div v-for="(engagementLevel, index) in this.engagementLevels"
      :key="'engagementLevel' + index"
      :index="index"
      :class="{ bold: engagementLevel.id === currentEngagementLevel.id }">
        {{engagementLevel.name}}
      </div>
    </div>
    <div class="engagementIcon">
      <EngagementLevelBadge
            :badge="currentEngagementLevel"
            :colored="false"
          >
      </EngagementLevelBadge>
    </div>
  </div>
</template>

<script>
import EngagementLevelBadge from '../../engagementLevel/EngagementLevelBadge.vue';

export default {
  name: 'CardEngagementLevel',
  props: ['profile'],
  components: {
    EngagementLevelBadge,
  },
  data() {
    return {
      downloadedImage: null,
    };
  },
  computed: {
    engagementLevelClass() {
      return this.currentEngagementLevel
        ? this.currentEngagementLevel.name.replace(' ', '-').toLowerCase()
        : '';
    },
    currentEngagementLevel() {
      if (!this.engagementLevels) {
        return null;
      }

      const copy = [...this.engagementLevels]
        .sort((a, b) => a.orderBy - b.orderBy)
        .filter(el => this.profile.points >= el.pointsToUnlock);

      return copy
        ? copy.reverse()[0]
        : this.engagementLevels[this.engagementLevels.length - 1];
    },
    engagementLevels() {
      return this.$store.getters['engagementLevelStore/engagementLevels'];
    },
  },
};
</script>

<style lang="scss" scoped>
.cardEngagementLevel {
  flex: 2;

  color: white;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  .list {
    font-size: 12px;

    .bold {
      font-weight: bold;
    }
  }

  .engagementIcon {
    width: 50%;
    height: 100%;
  }

  &.lincoln-savvy {
    background-image: linear-gradient(rgb(46, 49, 146),rgb(15, 117, 188))
  }

  &.lincoln-influential {
    background-image: linear-gradient(rgb(55, 34, 121), rgb(102, 45, 145))
  }

  &.lincoln-force {
    background-image: linear-gradient(rgb(129, 130, 133), rgb(189, 190, 192))
  }

  &.lincoln-legend {
    background-image: linear-gradient(rgb(36, 32, 33), rgb(88, 88, 90))
  }

  &.lincoln-notable {
    background-image: linear-gradient(rgb(239, 64, 54), rgb(239, 109, 72))
  }
}
.cardEngagementLevel > * {
  align-self: center;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
