<template>
  <div @click="toggleFollow" class="cardProfileFollow">
    <div class="copy" :class="disabledClick">
      <div v-if="copy === 'Follow'">
        <span>{{copy}}</span>
        <br />
        <font-awesome-icon :icon="['fas', 'user-plus']" />
      </div>
      <div v-else>
        <span>{{copy}}</span>
        <br />
        <font-awesome-icon :icon="['fas', 'user-minus']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardProfileFollow',
  async created() {
    await this.$store.dispatch('userFollowStore/getFollowList');
  },
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    async toggleFollow() {
      const profileOnView = this.$store.getters['profileViewStore/profile'];
      this.submitting = true;
      if (this.copy === 'Follow') {
        await this.$store.dispatch(
          'userFollowStore/followUser',
          profileOnView.id,
        );
      } else {
        await this.$store.dispatch(
          'userFollowStore/unFollowUser',
          profileOnView.id,
        );
      }

      await this.$store.dispatch('userFollowStore/getBrandStory');
      this.submitting = false;
    },
  },
  computed: {
    followList() {
      return this.$store.getters['userFollowStore/followList'];
    },
    onYourProfile() {
      return (
        this.$store.getters['profileViewStore/profile'].id
        === this.$store.getters['userStore/profile'].id
      );
    },
    copy() {
      for (let i = 0; i < this.followList.length; i += 1) {
        if (
          this.followList[i].id
          === this.$store.getters['profileViewStore/profile'].id
        ) {
          return 'Unfollow';
        }
      }
      return 'Follow';
    },
    disabledClick() {
      if (this.submitting) {
        return 'disable';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.cardProfileFollow {
  padding-top: 25px;
  .copy {
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    color: rgb(88, 94, 94);

    &:hover {
      color: $LincolnGrayDark;
    }

    &.disable {
      pointer-events: none;
    }
  }
}
</style>
