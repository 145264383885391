<template>
  <div class="cardPoints">
    <div class="pointsGrouping">
      <div class="key">Your Current Points</div><div class="value">{{profile.points}}</div>
    </div>
    <div class="pointsGrouping" v-if="pointsAway">
      <div class="key">Your are {{pointsAway}} away from</div>
      <div class="value">{{nextLevelName}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardPoints',
  props: ['profile'],
  computed: {
    engagementLevels() {
      return this.$store.getters['engagementLevelStore/engagementLevels'];
    },
    pointsAway() {
      const next = this.getNextLevel();
      return next
        ? next.pointsToUnlock - this.profile.points
        : 0;
    },
    nextLevelName() {
      const next = this.getNextLevel();
      return next
        ? next.name
        : '';
    },
  },
  methods: {
    getNextLevel() {
      const next = [...this.engagementLevels]
        .sort((a, b) => a.orderBy - b.orderBy)
        .filter(el => el.pointsToUnlock >= this.profile.points);

      return next
        ? next[0]
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardPoints {
  flex: 1;

  background-color: black;
  color: white;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.pointsGrouping {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    width: 92%;
    margin-bottom: 5%;


    &.bottomLine {
      padding-top: 10%;
      border-bottom: .5px solid white;
    }

    .key {
      flex: 2;
      font-size: 12px;
      text-align: left;
    }
    .value {
      flex: 2;
      text-align: right;
    }
  }
  .pointsGrouping > * {
    align-self: center;
  }

.cardPoints > * {
  align-self: center;
}
</style>
