<template>
  <div class="brandStoriesListByUser">
    <LoadingOverlay v-show="loadingData" />
    <masonry :cols="{default: 4, 1200: 2}" :gutter="{default: '15px'}" :key="key">
      <BrandStoryTile
        v-for="(brandStory, index) in this.brandStories"
        :key="'brandStoryTile' + brandStory.id"
        :brandStory="brandStory"
        :index="index"
      />
    </masonry>
  </div>
</template>

<script>
import BrandStoryTile from './BrandStoryTile.vue';
import LoadingOverlay from '../../../loaders/LoadingOverlay.vue';

export default {
  name: 'BrandStoriesListByUser',
  components: {
    BrandStoryTile,
    LoadingOverlay,
  },
  data() {
    return {
      key: true,
      loadingData: false,
    };
  },
  created() {
    if (this.brandStories.length === 0) {
      this.loadingData = true;
    }
  },
  watch: {
    brandStories() {
      this.loadingData = false;
    },
  },
  methods: {
    rerenderMasonry() {
      this.key = !this.key;
    },
  },
  computed: {
    cols() {
      const defaultCols = this.brandStories.length === 2 ? 2 : 3;

      return {
        default: defaultCols,
        990: 2,
        650: 1,
      };
    },
    brandStories() {
      return this.$store.getters['profileViewStore/brandStoriesByUser'];
    },
  },
};
</script>

<style lang="scss" scoped>
.brandStoriesListByUser {
  padding: 40px 0 0 0;
}
</style>
