<template>
  <div class="profileFull">
    <CardProfile :profile="profile" />
    <CardEngagementLevel :profile="profile" />
    <CardPoints v-show="onYourProfile" :profile="profile" />
  </div>
</template>

<script>
import CardProfile from './CardProfile.vue';
import CardEngagementLevel from './CardEngagementLevel.vue';
import CardPoints from './CardPoints.vue';

export default {
  name: 'ProfileFull',
  components: {
    CardProfile,
    CardEngagementLevel,
    CardPoints,
  },
  computed: {
    profile() {
      return this.$store.getters['profileViewStore/profile'];
    },
    profileYours() {
      return this.$store.getters['userStore/profile'];
    },
    onYourProfile() {
      return (
        this.$store.getters['profileViewStore/profile'].id === this.$store.getters['userStore/profile'].id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.profileFull {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 600px;
}
</style>
