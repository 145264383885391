var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardPoints" }, [
    _c("div", { staticClass: "pointsGrouping" }, [
      _c("div", { staticClass: "key" }, [_vm._v("Your Current Points")]),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.profile.points))]),
    ]),
    _vm.pointsAway
      ? _c("div", { staticClass: "pointsGrouping" }, [
          _c("div", { staticClass: "key" }, [
            _vm._v("Your are " + _vm._s(_vm.pointsAway) + " away from"),
          ]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.nextLevelName)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }