var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "div",
      { staticClass: "profile" },
      [
        _c("ProfileFull", { staticClass: "left" }),
        _c("div", { staticClass: "middle" }, [
          _c(
            "div",
            { staticClass: "groupMyPosts" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v("TOP POSTS BY " + _vm._s(_vm.profile.namePreferred)),
              ]),
              _c("div", { staticClass: "divider" }),
              _c("BrandStoriesListByUser"),
            ],
            1
          ),
          _vm.onYourProfile
            ? _c(
                "div",
                { staticClass: "groupFavorite" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("MY FAVORITES")]),
                  _c("div", { staticClass: "divider" }),
                  _c("BrandStoriesListFavorites"),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.onYourProfile
          ? _c(
              "div",
              { staticClass: "right" },
              [_c("UserFollowListToFollow")],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }