<template>
  <div class="content-wrapper">
    <div class="profile">
      <ProfileFull class="left" />
      <div class="middle">
        <div class="groupMyPosts">
          <div class="title">TOP POSTS BY {{profile.namePreferred}}</div>
          <div class="divider"></div>
          <BrandStoriesListByUser />
        </div>
        <div v-if="onYourProfile" class="groupFavorite">
          <div class="title">MY FAVORITES</div>
          <div class="divider"></div>
          <BrandStoriesListFavorites />
        </div>
      </div>
      <div v-if="onYourProfile" class="right">
        <UserFollowListToFollow />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileFull from '../components/profile/profileFull/ProfileFull.vue';
import BrandStoriesListFavorites from '../components/stories/brandStories/list/BrandStoriesListFavorites.vue';
import UserFollowListToFollow from '../components/userFollowList/UserFollowListToFollow.vue';
import BrandStoriesListByUser from '../components/stories/brandStories/list/BrandStoriesListByUser.vue';

export default {
  name: 'Profile',
  props: ['id'],
  components: {
    ProfileFull,
    BrandStoriesListFavorites,
    UserFollowListToFollow,
    BrandStoriesListByUser,
  },
  async created() {
    this.$analytics.trackPageView();
    await this.$store.dispatch('profileViewStore/getProfile', this.id);
  },
  watch: {
    id(newId) {
      this.$store.dispatch('profileViewStore/getProfile', newId);
    },
  },
  computed: {
    onYourProfile() {
      return (
        this.$store.getters['profileViewStore/profile'].id
        === this.$store.getters['userStore/profile'].id
      );
    },
    profile() {
      return this.$store.getters['profileViewStore/profile'];
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .left {
    flex: 1;
    margin-bottom: 40px;
  }

  .middle {
    flex: 3;

    @media screen and (min-width: 553px) {
      padding: 0 20px;
    }

    .groupMyPosts {
      margin-bottom: 40px;

      .title {
        font-size: 20px;
        text-transform:uppercase;
      }

      .divider {
        height: 1px;
        background: $LincolnOrange;
        margin-top: 20px;
      }
    }

    .groupFavorite {
      .title {
        font-size: 20px;
      }

      .divider {
        height: 1px;
        background: $LincolnOrange;
        margin-top: 20px;
      }
    }
  }

  .right {
    padding: 0 10px;
    flex: 1;
  }
}
</style>
