<template>
  <div class="cardProfile">
    <div class="circleBorderProfileImg" :class="engagementLevelClass">
      <UserImageThumbnail :thumbnailAssetId="profile.thumbnailAssetId" :class="'imgProfile'" />
      <div class="editProfile" @click.prevent="editUserProfile" v-show="this.onYourProfile">
        <font-awesome-icon class="icon" :icon="['fas', 'pencil']" />
      </div>
    </div>
    <div class="txt bold">{{profile.nameFirst}} {{profile.nameLast}}</div>
    <div v-if="position" class="txt">{{position}}</div>
    <div v-if="profile.email" class="txt">
      <a class="email" :href="'mailto:' + profile.email">{{profile.email}}</a>
    </div>
    <div class="txt">{{profile.dealership}}</div>
    <div class="followerSection">
      <div>
        <div class="txt bold large">{{profile.followsCount}}</div>
        <div class="txt small">FOLLOWERS</div>
      </div>
      <div class="middle">
        <div class="txt bold large">{{postCount}}</div>
        <div class="txt small">POSTS</div>
      </div>
      <div>
        <div class="txt bold large">{{profile.likes}}</div>
        <div class="txt small">LIKES</div>
      </div>
    </div>
    <BrandStorySubmissionContainerAlt v-if="onYourProfile" />
    <CardProfileFollow v-if="!onYourProfile" />
  </div>
</template>

<script>
import BrandStorySubmissionContainerAlt from '../../stories/brandStories/BrandStorySubmissionContainerAlt.vue';
import CardProfileFollow from './CardProfileFollow.vue';
import ProfileSubmission from '../UserProfileSubmission.vue';
import UserImageThumbnail from '../UserImageThumbnail.vue';

export default {
  name: 'CardProfile',
  props: ['profile'],
  components: {
    BrandStorySubmissionContainerAlt,
    CardProfileFollow,
    UserImageThumbnail,
  },
  computed: {
    position() {
      const starsPosition = this.profile.position && this.profile.position.name;

      return this.profile.nonStarsPosition || starsPosition;
    },
    engagementLevelClass() {
      return this.currentEngagementLevel
        ? this.currentEngagementLevel.name.replace(' ', '-').toLowerCase()
        : '';
    },
    currentEngagementLevel() {
      if (!this.engagementLevels) {
        return null;
      }

      const copy = [...this.engagementLevels]
        .sort((a, b) => a.orderBy - b.orderBy)
        .filter(el => this.profile.points >= el.pointsToUnlock);

      return copy
        ? copy.reverse()[0]
        : this.engagementLevels[this.engagementLevels.length - 1];
    },
    engagementLevels() {
      return this.$store.getters['engagementLevelStore/engagementLevels'];
    },
    onYourProfile() {
      return (
        this.$store.getters['profileViewStore/profile'].id
        === this.$store.getters['userStore/profile'].id
      );
    },
    postCount() {
      return this.$store.getters['profileViewStore/brandStoriesByUser'].length;
    },
  },
  methods: {
    async editUserProfile() {
      if (this.onYourProfile) {
        const name = 'ProfileEditSubmission';
        this.$modal.show(
          ProfileSubmission,
          {
            profile: this.profile,
            name,
          },
          {
            name,
            adaptive: true,
            height: 'auto',
            width: '70%',
            scrollable: true,
          },
        );
      }
    },
  },
  async created() {
    await this.$store.dispatch('positionStore/getPositions');
  },
};
</script>

<style lang="scss" scoped>
.cardProfile {
  flex: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: rgb(180, 186, 186);
  color: black;
  padding: 10px;

  .circleBorderProfileImg {
    background-color: rbga(0, 0, 0, 0);
    border: 3px solid $ProfileCircleBorder;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    height: 100px;
    width: 100px;
    position: relative;
    margin-bottom: 10px;

    .imgProfile {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      display: inline-block;
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
    &.lincoln-savvy {
      border-color: rgb(46, 49, 146);
    }

    &.lincoln-influential {
      border-color: rgb(55, 34, 121);
    }

    &.lincoln-force {
      border-color: rgb(129, 130, 133);
    }

    &.lincoln-legend {
      border-color: rgb(36, 32, 33);
    }

    &.lincoln-notable {
      border-color: rgb(239, 64, 54);
    }

    .editProfile {
      color: white;
      position: absolute;
      bottom: -10px;
      right: 0px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      background: rgb(43, 63, 154);
      font-size: 1.125rem;

      &:hover {
        background: $LincolnOrange;
        cursor: pointer;
      }
    }
  }

  .txt {
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    &.bold {
      font-weight: bold;
    }
    &.large {
      font-size: 12px;
    }
    &.small {
      font-size: 8px;
    }

    .email {
      text-decoration: none;
      font-weight: $bold;
      transition: all 0.3s;
      color: #000;

      &:hover {
        color: $LincolnOrange;
      }
    }
  }

  .followerSection {
    border-top: 0.5px solid black;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    .middle {
      border-left: 0.5px solid black;
      border-right: 0.5px solid black;
    }
  }
  .followerSection > * {
    padding-left: 10px;
    padding-right: 10px;
    width: 50px;
  }
}

.cardProfile > * {
  align-self: center;
}
</style>
