var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [_vm._v("SUBMIT YOUR")]),
    _c(
      "div",
      { staticClass: "upload-icon", on: { click: _vm.showSubmission } },
      [
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "arrow-from-top"], flip: "vertical" },
        }),
      ],
      1
    ),
    _c("p", [_vm._v("STORY")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }