var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profileFull" },
    [
      _c("CardProfile", { attrs: { profile: _vm.profile } }),
      _c("CardEngagementLevel", { attrs: { profile: _vm.profile } }),
      _c("CardPoints", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.onYourProfile,
            expression: "onYourProfile",
          },
        ],
        attrs: { profile: _vm.profile },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }