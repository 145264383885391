var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userProfileSubmission" },
    [
      _c("LincolnEffortlessExperienceLogoDark"),
      _c("h2", [_vm._v("Edit Profile")]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit($event)
            },
          },
        },
        [
          _c("label", { staticClass: "status" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Name: ")]),
            _c("div", { staticClass: "control" }, [
              _vm._v(
                _vm._s(_vm.profile.nameFirst) +
                  " " +
                  _vm._s(_vm.profile.nameLast)
              ),
            ]),
          ]),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Preferred Name",
              name: "preferredName",
              placeholder: "Preferred Name",
            },
            model: {
              value: _vm.profile.namePreferred,
              callback: function ($$v) {
                _vm.$set(_vm.profile, "namePreferred", $$v)
              },
              expression: "profile.namePreferred",
            },
          }),
          _vm.hasPositionId
            ? _c("model-list-select", {
                attrs: {
                  list: _vm.positions,
                  "option-value": "id",
                  "option-text": "name",
                  placeholder: "Position",
                },
                model: {
                  value: _vm.profile.position,
                  callback: function ($$v) {
                    _vm.$set(_vm.profile, "position", $$v)
                  },
                  expression: "profile.position",
                },
              })
            : _vm._e(),
          !_vm.hasPositionId
            ? _c("TextBox", {
                staticClass: "textBox",
                attrs: {
                  label: "Position",
                  name: "position",
                  required: true,
                  placeholder: "Position",
                },
                model: {
                  value: _vm.profile.nonStarsPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.profile, "nonStarsPosition", $$v)
                  },
                  expression: "profile.nonStarsPosition",
                },
              })
            : _vm._e(),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Email",
              name: "email",
              type: "email",
              required: true,
              placeholder: "Email",
            },
            model: {
              value: _vm.profile.email,
              callback: function ($$v) {
                _vm.$set(_vm.profile, "email", $$v)
              },
              expression: "profile.email",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Phone",
              name: "phone",
              required: true,
              placeholder: "Phone",
            },
            model: {
              value: _vm.profile.phone,
              callback: function ($$v) {
                _vm.$set(_vm.profile, "phone", $$v)
              },
              expression: "profile.phone",
            },
          }),
          _c("ImageCropperForProfile", {
            staticClass: "imagePreviewer",
            attrs: { profileData: _vm.profile },
            on: { updatedImage: _vm.updatedImage },
          }),
          _c("ButtonSubmit", {
            attrs: { copy: "Save" },
            on: { callback: _vm.submit },
          }),
          _c("ButtonCancel", {
            staticClass: "cancel",
            on: { click: _vm.closeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }