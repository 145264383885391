<template>
  <div class="userProfileSubmission">
    <LincolnEffortlessExperienceLogoDark />
    <h2>Edit Profile</h2>

    <form @submit.prevent="submit">
      <label class="status">
        <div class="label">Name: </div>
        <div class="control">{{profile.nameFirst}} {{profile.nameLast}}</div>
      </label>
      <TextBox :label="'Preferred Name'"
               :name="'preferredName'"
               v-model="profile.namePreferred"
               class="textBox"
               :placeholder="'Preferred Name'" />
      <model-list-select v-if="hasPositionId"
                         :list="positions"
                         option-value="id"
                         option-text="name"
                         v-model="profile.position"
                         placeholder="Position" />
      <TextBox v-if="!hasPositionId"
               :label="'Position'"
               :name="'position'"
               :required="true"
               v-model="profile.nonStarsPosition"
               :placeholder="'Position'"
               class="textBox" />
      <TextBox :label="'Email'"
               :name="'email'"
               :type="'email'"
               :required="true"
               v-model="profile.email"
               class="textBox"
               :placeholder="'Email'" />
      <TextBox :label="'Phone'"
               :name="'phone'"
               :required="true"
               v-model="profile.phone"
               class="textBox"
               :placeholder="'Phone'" />
      <ImageCropperForProfile class="imagePreviewer"
                              :profileData="profile"
                              @updatedImage="updatedImage" />
      <ButtonSubmit :copy="'Save'"
                    @callback="submit" />
      <ButtonCancel class="cancel" @click="closeModal" />
    </form>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select';
import TextBox from '../formElements/TextBox.vue';
import LincolnEffortlessExperienceLogoDark from '../LincolnEffortlessExperienceLogoDark.vue';
import ImageCropperForProfile from '../imageManipulation/ImageCropperForProfile.vue';
import ButtonSubmit from '../formElements/buttons/buttonSubmit.vue';
import ButtonCancel from '../formElements/buttons/ButtonCancel.vue';

export default {
  name: 'UserProfileSubmission',
  components: {
    TextBox,
    LincolnEffortlessExperienceLogoDark,
    ImageCropperForProfile,
    ButtonSubmit,
    ModelListSelect,
    ButtonCancel,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    onUserProfileEdited: {
      type: Function,
      default: () => { },
    },
    options: Array,
  },
  computed: {
    positions() {
      return this.$store.getters['positionStore/positions'];
    },
    hasPositionId() {
      return this.profile.position && this.profile.position.id;
    },
  },
  methods: {
    async submit(event) {
      if (!event.target.form.reportValidity()) {
        this.$store.dispatch('buttonSubmitStore/done');
        return;
      }

      await this.$store.dispatch('userStore/updateProfile', this.profile);
      this.onUserProfileEdited(this.profile);
      this.closeModal();
    },
    async closeModal() {
      await this.$store.dispatch('profileViewStore/getProfile', this.profile.id);
      this.$modal.hide(this.name);
    },
    async updatedImage(file) {
      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', file);
      this.profile.thumbnailAssetId = thumbnailImageUploaded.id;
      this.profile.thumbnailAsset = thumbnailImageUploaded;
    },
  },
};
</script>

<style lang="scss" scoped>
.userProfileSubmission {
  padding: 2em;
  background-color: white;

  .status {
    display: flex;
    align-items: center;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnGrayDark;
    padding: 0 15px;
    margin: 2em 0;

    .label {
      @include font-size(1rem);
      margin-right: 10px;
      line-height: 3em;
    }

    select {
      border: none;
      @include font-size(1rem);
      line-height: 3em;
    }
  }

  .storyBody {
    display: block;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnGrayDark;
    padding: 10px 10px 0px;
    margin: 2em 0;
  }

  .imagePreviewer {
    display: block;
    background: white;
    color: $LincolnGrayDark;
    border: 1px solid $LincolnGrayDark;
    padding: 10px 10px 0px;
    margin: 2em 0;
  }
}

.options {
  color: $LincolnGrayLight;
}

.textBox {
  margin: 2em 0;
  display: block;
}

.cancel{
  margin-left: 1rem;
}
</style>
