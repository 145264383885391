<template>
  <div>
    <p>SUBMIT YOUR</p>
    <div @click="showSubmission" class="upload-icon">
      <font-awesome-icon :icon="['fal', 'arrow-from-top']" flip="vertical" />
    </div>
    <p>STORY</p>
  </div>
</template>

<script>
import BrandStorySubmission from './BrandStorySubmission.vue';

export default {
  name: 'BrandStorySubmissionContainerAlt',
  methods: {
    showSubmission() {
      const name = 'BrandStorySubmission';

      this.$modal.show(BrandStorySubmission, { name }, {
        name,
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  color: white;

  .upload-icon {
    border: 2px solid #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 0 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      color: $LincolnOrange;
      border-color: $LincolnOrange;
    }
  }
}

p,
button {
  display: inline-block;
}
</style>
